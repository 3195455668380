import React from 'react';
import './menubar.css';

const MenuBar = () => {
  return (
    <nav className="menu-bar">
      <ul>
        <li><a href="/">특검 청원</a></li>
        <li><a href="/red">정당해산 청원</a></li>
        <li><a href="/kookhim55out">국힘소속55인</a></li>
        <li><a href="https://msg.kick-yoon.com/">문자행동</a></li>
        <li><a href="/contact">Contact</a></li>
      </ul>
    </nav>
  );
};

export default MenuBar;
